import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0c1071a8 = () => interopDefault(import('..\\pages\\allgame.vue' /* webpackChunkName: "pages/allgame" */))
const _2c085c9b = () => interopDefault(import('..\\pages\\auctionPage\\index.vue' /* webpackChunkName: "pages/auctionPage/index" */))
const _b2ebf7a4 = () => interopDefault(import('..\\pages\\black\\index.vue' /* webpackChunkName: "pages/black/index" */))
const _62cec532 = () => interopDefault(import('..\\pages\\center\\index.vue' /* webpackChunkName: "pages/center/index" */))
const _74ebb6a6 = () => interopDefault(import('..\\pages\\contact\\index.vue' /* webpackChunkName: "pages/contact/index" */))
const _ad6cf746 = () => interopDefault(import('..\\pages\\Conversations\\index.vue' /* webpackChunkName: "pages/Conversations/index" */))
const _38cd205e = () => interopDefault(import('..\\pages\\dealshow\\index.vue' /* webpackChunkName: "pages/dealshow/index" */))
const _0b990fba = () => interopDefault(import('..\\pages\\forgetPassword.vue' /* webpackChunkName: "pages/forgetPassword" */))
const _3ad78d39 = () => interopDefault(import('..\\pages\\gj-wzry\\index.vue' /* webpackChunkName: "pages/gj-wzry/index" */))
const _17446569 = () => interopDefault(import('..\\pages\\jump.vue' /* webpackChunkName: "pages/jump" */))
const _018f14ab = () => interopDefault(import('..\\pages\\kfyz\\index.vue' /* webpackChunkName: "pages/kfyz/index" */))
const _7d69f089 = () => interopDefault(import('..\\pages\\log\\index.vue' /* webpackChunkName: "pages/log/index" */))
const _4497f43e = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _7e54657d = () => interopDefault(import('..\\pages\\moreAnchor\\index.vue' /* webpackChunkName: "pages/moreAnchor/index" */))
const _1a61f734 = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _0d60d3de = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages/register" */))
const _3bbc3866 = () => interopDefault(import('..\\pages\\test.vue' /* webpackChunkName: "pages/test" */))
const _65614237 = () => interopDefault(import('..\\pages\\wares\\index.vue' /* webpackChunkName: "pages/wares/index" */))
const _58d0cafd = () => interopDefault(import('..\\pages\\waresNew\\index.vue' /* webpackChunkName: "pages/waresNew/index" */))
const _c4573e60 = () => interopDefault(import('..\\pages\\Bargbuy\\Babuy.vue' /* webpackChunkName: "pages/Bargbuy/Babuy" */))
const _f4269668 = () => interopDefault(import('..\\pages\\Bargbuy\\Partialpayment.vue' /* webpackChunkName: "pages/Bargbuy/Partialpayment" */))
const _42efc919 = () => interopDefault(import('..\\pages\\black\\blackList.vue' /* webpackChunkName: "pages/black/blackList" */))
const _4610a329 = () => interopDefault(import('..\\pages\\black\\center.vue' /* webpackChunkName: "pages/black/center" */))
const _0d32948c = () => interopDefault(import('..\\pages\\black\\releaseBlack.vue' /* webpackChunkName: "pages/black/releaseBlack" */))
const _03f89824 = () => interopDefault(import('..\\pages\\black\\zhgs.vue' /* webpackChunkName: "pages/black/zhgs" */))
const _7b25b892 = () => interopDefault(import('..\\pages\\center\\buyers\\index.vue' /* webpackChunkName: "pages/center/buyers/index" */))
const _084a3d20 = () => interopDefault(import('..\\pages\\center\\sell\\index.vue' /* webpackChunkName: "pages/center/sell/index" */))
const _491101c3 = () => interopDefault(import('..\\pages\\dealshow\\selltime.vue' /* webpackChunkName: "pages/dealshow/selltime" */))
const _f4f395ea = () => interopDefault(import('..\\pages\\fastGold\\fastGoldOrder.vue' /* webpackChunkName: "pages/fastGold/fastGoldOrder" */))
const _df3e5802 = () => interopDefault(import('..\\pages\\gj-wzry\\hpjyEvaluator.vue' /* webpackChunkName: "pages/gj-wzry/hpjyEvaluator" */))
const _09b9dcc7 = () => interopDefault(import('..\\pages\\specilPage\\createAccountInfo.vue' /* webpackChunkName: "pages/specilPage/createAccountInfo" */))
const _0c82d261 = () => interopDefault(import('..\\pages\\specilPage\\downloadApp.vue' /* webpackChunkName: "pages/specilPage/downloadApp" */))
const _0bc5ec00 = () => interopDefault(import('..\\pages\\specilPage\\hpjyEvaluator.vue' /* webpackChunkName: "pages/specilPage/hpjyEvaluator" */))
const _c2ca8c5a = () => interopDefault(import('..\\pages\\center\\activity\\play.vue' /* webpackChunkName: "pages/center/activity/play" */))
const _798bafde = () => interopDefault(import('..\\pages\\center\\assets\\assetsFinancing.vue' /* webpackChunkName: "pages/center/assets/assetsFinancing" */))
const _5048a83c = () => interopDefault(import('..\\pages\\center\\buyers\\BuyBargain.vue' /* webpackChunkName: "pages/center/buyers/BuyBargain" */))
const _5d32106c = () => interopDefault(import('..\\pages\\center\\buyers\\buyBargainGoods.vue' /* webpackChunkName: "pages/center/buyers/buyBargainGoods" */))
const _1fb509b5 = () => interopDefault(import('..\\pages\\center\\buyers\\buyComplaint.vue' /* webpackChunkName: "pages/center/buyers/buyComplaint" */))
const _3eb8b542 = () => interopDefault(import('..\\pages\\center\\buyers\\buyFastGold.vue' /* webpackChunkName: "pages/center/buyers/buyFastGold" */))
const _6377f1b3 = () => interopDefault(import('..\\pages\\center\\buyers\\buyFastGoldDetail.vue' /* webpackChunkName: "pages/center/buyers/buyFastGoldDetail" */))
const _3431a27e = () => interopDefault(import('..\\pages\\center\\buyers\\buyGoodsOrder.vue' /* webpackChunkName: "pages/center/buyers/buyGoodsOrder" */))
const _12f99512 = () => interopDefault(import('..\\pages\\center\\buyers\\buyGuarantee.vue' /* webpackChunkName: "pages/center/buyers/buyGuarantee" */))
const _d28897e2 = () => interopDefault(import('..\\pages\\center\\buyers\\buyLikedGoods.vue' /* webpackChunkName: "pages/center/buyers/buyLikedGoods" */))
const _dd2e706e = () => interopDefault(import('..\\pages\\center\\buyers\\buyOrderDetail.vue' /* webpackChunkName: "pages/center/buyers/buyOrderDetail" */))
const _60cc75a6 = () => interopDefault(import('..\\pages\\center\\buyers\\buyOrderInfo.vue' /* webpackChunkName: "pages/center/buyers/buyOrderInfo" */))
const _5ac33b45 = () => interopDefault(import('..\\pages\\center\\buyers\\buySeckillOrder.vue' /* webpackChunkName: "pages/center/buyers/buySeckillOrder" */))
const _48dc4fa9 = () => interopDefault(import('..\\pages\\center\\buyers\\lotteryRecords.vue' /* webpackChunkName: "pages/center/buyers/lotteryRecords" */))
const _7329189d = () => interopDefault(import('..\\pages\\center\\buyers\\sellerOrderInfo.vue' /* webpackChunkName: "pages/center/buyers/sellerOrderInfo" */))
const _3915697f = () => interopDefault(import('..\\pages\\center\\extend\\extendMyExtension.vue' /* webpackChunkName: "pages/center/extend/extendMyExtension" */))
const _7d3e15d0 = () => interopDefault(import('..\\pages\\center\\extend\\qrCode.vue' /* webpackChunkName: "pages/center/extend/qrCode" */))
const _24a968b6 = () => interopDefault(import('..\\pages\\center\\extend\\sellgooSn.vue' /* webpackChunkName: "pages/center/extend/sellgooSn" */))
const _f85aefbe = () => interopDefault(import('..\\pages\\center\\feedback\\complaintcustomerservice.vue' /* webpackChunkName: "pages/center/feedback/complaintcustomerservice" */))
const _550c341e = () => interopDefault(import('..\\pages\\center\\feedback\\feedbackandsuggestion.vue' /* webpackChunkName: "pages/center/feedback/feedbackandsuggestion" */))
const _0af51672 = () => interopDefault(import('..\\pages\\center\\feedback\\feedbackrecording.vue' /* webpackChunkName: "pages/center/feedback/feedbackrecording" */))
const _6fefe920 = () => interopDefault(import('..\\pages\\center\\log\\history.vue' /* webpackChunkName: "pages/center/log/history" */))
const _364cf0ed = () => interopDefault(import('..\\pages\\center\\myself\\accountSecurity\\index.vue' /* webpackChunkName: "pages/center/myself/accountSecurity/index" */))
const _f7d1c168 = () => interopDefault(import('..\\pages\\center\\myself\\buyIdentity.vue' /* webpackChunkName: "pages/center/myself/buyIdentity" */))
const _49976b90 = () => interopDefault(import('..\\pages\\center\\myself\\cashWithdrawal.vue' /* webpackChunkName: "pages/center/myself/cashWithdrawal" */))
const _d8155898 = () => interopDefault(import('..\\pages\\center\\myself\\changeEmail.vue' /* webpackChunkName: "pages/center/myself/changeEmail" */))
const _1f4fd3fb = () => interopDefault(import('..\\pages\\center\\myself\\changePayPassword.vue' /* webpackChunkName: "pages/center/myself/changePayPassword" */))
const _4f85e6f4 = () => interopDefault(import('..\\pages\\center\\myself\\changePhone.vue' /* webpackChunkName: "pages/center/myself/changePhone" */))
const _1dd280a0 = () => interopDefault(import('..\\pages\\center\\myself\\depositWithdraw.vue' /* webpackChunkName: "pages/center/myself/depositWithdraw" */))
const _8a21e1c4 = () => interopDefault(import('..\\pages\\center\\myself\\identityAuthentication.vue' /* webpackChunkName: "pages/center/myself/identityAuthentication" */))
const _0a2c680e = () => interopDefault(import('..\\pages\\center\\myself\\myselfInfo.vue' /* webpackChunkName: "pages/center/myself/myselfInfo" */))
const _214f9322 = () => interopDefault(import('..\\pages\\center\\myself\\recharge.vue' /* webpackChunkName: "pages/center/myself/recharge" */))
const _1aa3a8ae = () => interopDefault(import('..\\pages\\center\\myself\\thirdPartyBinding.vue' /* webpackChunkName: "pages/center/myself/thirdPartyBinding" */))
const _a1fd3810 = () => interopDefault(import('..\\pages\\center\\sell\\sellBargainGoods.vue' /* webpackChunkName: "pages/center/sell/sellBargainGoods" */))
const _d04fc12c = () => interopDefault(import('..\\pages\\center\\sell\\sellOrder.vue' /* webpackChunkName: "pages/center/sell/sellOrder" */))
const _0296bc3c = () => interopDefault(import('..\\pages\\center\\sell\\sellOutGoods.vue' /* webpackChunkName: "pages/center/sell/sellOutGoods" */))
const _78499102 = () => interopDefault(import('..\\pages\\center\\sell\\Sellreceive.vue' /* webpackChunkName: "pages/center/sell/Sellreceive" */))
const _d70d36c2 = () => interopDefault(import('..\\pages\\center\\sell\\sellSuccess.vue' /* webpackChunkName: "pages/center/sell/sellSuccess" */))
const _907ba59e = () => interopDefault(import('..\\pages\\center\\sell\\sellTab.vue' /* webpackChunkName: "pages/center/sell/sellTab" */))
const _d32a4740 = () => interopDefault(import('..\\pages\\center\\myself\\accountSecurity\\changePassword.vue' /* webpackChunkName: "pages/center/myself/accountSecurity/changePassword" */))
const _53b55b79 = () => interopDefault(import('..\\pages\\center\\myself\\accountSecurity\\changePhone.vue' /* webpackChunkName: "pages/center/myself/accountSecurity/changePhone" */))
const _7af05127 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _4d249b56 = () => interopDefault(import('..\\pages\\black\\_sn.vue' /* webpackChunkName: "pages/black/_sn" */))
const _4f863cde = () => interopDefault(import('..\\pages\\detail\\_sn.vue' /* webpackChunkName: "pages/detail/_sn" */))
const _d4e8464c = () => interopDefault(import('..\\pages\\JDetail\\_sn.vue' /* webpackChunkName: "pages/JDetail/_sn" */))
const _1c2ca82e = () => interopDefault(import('..\\pages\\news\\_code.vue' /* webpackChunkName: "pages/news/_code" */))
const _385da63f = () => interopDefault(import('..\\pages\\goods\\_sn\\order.vue' /* webpackChunkName: "pages/goods/_sn/order" */))
const _543ed64e = () => interopDefault(import('..\\pages\\goods\\_sn\\pay.vue' /* webpackChunkName: "pages/goods/_sn/pay" */))
const _29b88a34 = () => interopDefault(import('..\\pages\\goods\\_sn\\success.vue' /* webpackChunkName: "pages/goods/_sn/success" */))
const _f6b76702 = () => interopDefault(import('..\\pages\\wares\\_sn\\order.vue' /* webpackChunkName: "pages/wares/_sn/order" */))
const _77c869ce = () => interopDefault(import('..\\pages\\wares\\_sn\\pay.vue' /* webpackChunkName: "pages/wares/_sn/pay" */))
const _7eeea074 = () => interopDefault(import('..\\pages\\wares\\_sn\\success.vue' /* webpackChunkName: "pages/wares/_sn/success" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/allgame",
    component: _0c1071a8,
    name: "allgame"
  }, {
    path: "/auctionPage",
    component: _2c085c9b,
    name: "auctionPage"
  }, {
    path: "/black",
    component: _b2ebf7a4,
    name: "black"
  }, {
    path: "/center",
    component: _62cec532,
    name: "center"
  }, {
    path: "/contact",
    component: _74ebb6a6,
    name: "contact"
  }, {
    path: "/Conversations",
    component: _ad6cf746,
    name: "Conversations"
  }, {
    path: "/dealshow",
    component: _38cd205e,
    name: "dealshow"
  }, {
    path: "/forgetPassword",
    component: _0b990fba,
    name: "forgetPassword"
  }, {
    path: "/gj-wzry",
    component: _3ad78d39,
    name: "gj-wzry"
  }, {
    path: "/jump",
    component: _17446569,
    name: "jump"
  }, {
    path: "/kfyz",
    component: _018f14ab,
    name: "kfyz"
  }, {
    path: "/log",
    component: _7d69f089,
    name: "log"
  }, {
    path: "/login",
    component: _4497f43e,
    name: "login"
  }, {
    path: "/moreAnchor",
    component: _7e54657d,
    name: "moreAnchor"
  }, {
    path: "/news",
    component: _1a61f734,
    name: "news"
  }, {
    path: "/register",
    component: _0d60d3de,
    name: "register"
  }, {
    path: "/test",
    component: _3bbc3866,
    name: "test"
  }, {
    path: "/wares",
    component: _65614237,
    name: "wares"
  }, {
    path: "/waresNew",
    component: _58d0cafd,
    name: "waresNew"
  }, {
    path: "/Bargbuy/Babuy",
    component: _c4573e60,
    name: "Bargbuy-Babuy"
  }, {
    path: "/Bargbuy/Partialpayment",
    component: _f4269668,
    name: "Bargbuy-Partialpayment"
  }, {
    path: "/black/blackList",
    component: _42efc919,
    name: "black-blackList"
  }, {
    path: "/black/center",
    component: _4610a329,
    name: "black-center"
  }, {
    path: "/black/releaseBlack",
    component: _0d32948c,
    name: "black-releaseBlack"
  }, {
    path: "/black/zhgs",
    component: _03f89824,
    name: "black-zhgs"
  }, {
    path: "/center/buyers",
    component: _7b25b892,
    name: "center-buyers"
  }, {
    path: "/center/sell",
    component: _084a3d20,
    name: "center-sell"
  }, {
    path: "/dealshow/selltime",
    component: _491101c3,
    name: "dealshow-selltime"
  }, {
    path: "/fastGold/fastGoldOrder",
    component: _f4f395ea,
    name: "fastGold-fastGoldOrder"
  }, {
    path: "/gj-wzry/hpjyEvaluator",
    component: _df3e5802,
    name: "gj-wzry-hpjyEvaluator"
  }, {
    path: "/specilPage/createAccountInfo",
    component: _09b9dcc7,
    name: "specilPage-createAccountInfo"
  }, {
    path: "/specilPage/downloadApp",
    component: _0c82d261,
    name: "specilPage-downloadApp"
  }, {
    path: "/specilPage/hpjyEvaluator",
    component: _0bc5ec00,
    name: "specilPage-hpjyEvaluator"
  }, {
    path: "/center/activity/play",
    component: _c2ca8c5a,
    name: "center-activity-play"
  }, {
    path: "/center/assets/assetsFinancing",
    component: _798bafde,
    name: "center-assets-assetsFinancing"
  }, {
    path: "/center/buyers/BuyBargain",
    component: _5048a83c,
    name: "center-buyers-BuyBargain"
  }, {
    path: "/center/buyers/buyBargainGoods",
    component: _5d32106c,
    name: "center-buyers-buyBargainGoods"
  }, {
    path: "/center/buyers/buyComplaint",
    component: _1fb509b5,
    name: "center-buyers-buyComplaint"
  }, {
    path: "/center/buyers/buyFastGold",
    component: _3eb8b542,
    name: "center-buyers-buyFastGold"
  }, {
    path: "/center/buyers/buyFastGoldDetail",
    component: _6377f1b3,
    name: "center-buyers-buyFastGoldDetail"
  }, {
    path: "/center/buyers/buyGoodsOrder",
    component: _3431a27e,
    name: "center-buyers-buyGoodsOrder"
  }, {
    path: "/center/buyers/buyGuarantee",
    component: _12f99512,
    name: "center-buyers-buyGuarantee"
  }, {
    path: "/center/buyers/buyLikedGoods",
    component: _d28897e2,
    name: "center-buyers-buyLikedGoods"
  }, {
    path: "/center/buyers/buyOrderDetail",
    component: _dd2e706e,
    name: "center-buyers-buyOrderDetail"
  }, {
    path: "/center/buyers/buyOrderInfo",
    component: _60cc75a6,
    name: "center-buyers-buyOrderInfo"
  }, {
    path: "/center/buyers/buySeckillOrder",
    component: _5ac33b45,
    name: "center-buyers-buySeckillOrder"
  }, {
    path: "/center/buyers/lotteryRecords",
    component: _48dc4fa9,
    name: "center-buyers-lotteryRecords"
  }, {
    path: "/center/buyers/sellerOrderInfo",
    component: _7329189d,
    name: "center-buyers-sellerOrderInfo"
  }, {
    path: "/center/extend/extendMyExtension",
    component: _3915697f,
    name: "center-extend-extendMyExtension"
  }, {
    path: "/center/extend/qrCode",
    component: _7d3e15d0,
    name: "center-extend-qrCode"
  }, {
    path: "/center/extend/sellgooSn",
    component: _24a968b6,
    name: "center-extend-sellgooSn"
  }, {
    path: "/center/feedback/complaintcustomerservice",
    component: _f85aefbe,
    name: "center-feedback-complaintcustomerservice"
  }, {
    path: "/center/feedback/feedbackandsuggestion",
    component: _550c341e,
    name: "center-feedback-feedbackandsuggestion"
  }, {
    path: "/center/feedback/feedbackrecording",
    component: _0af51672,
    name: "center-feedback-feedbackrecording"
  }, {
    path: "/center/log/history",
    component: _6fefe920,
    name: "center-log-history"
  }, {
    path: "/center/myself/accountSecurity",
    component: _364cf0ed,
    name: "center-myself-accountSecurity"
  }, {
    path: "/center/myself/buyIdentity",
    component: _f7d1c168,
    name: "center-myself-buyIdentity"
  }, {
    path: "/center/myself/cashWithdrawal",
    component: _49976b90,
    name: "center-myself-cashWithdrawal"
  }, {
    path: "/center/myself/changeEmail",
    component: _d8155898,
    name: "center-myself-changeEmail"
  }, {
    path: "/center/myself/changePayPassword",
    component: _1f4fd3fb,
    name: "center-myself-changePayPassword"
  }, {
    path: "/center/myself/changePhone",
    component: _4f85e6f4,
    name: "center-myself-changePhone"
  }, {
    path: "/center/myself/depositWithdraw",
    component: _1dd280a0,
    name: "center-myself-depositWithdraw"
  }, {
    path: "/center/myself/identityAuthentication",
    component: _8a21e1c4,
    name: "center-myself-identityAuthentication"
  }, {
    path: "/center/myself/myselfInfo",
    component: _0a2c680e,
    name: "center-myself-myselfInfo"
  }, {
    path: "/center/myself/recharge",
    component: _214f9322,
    name: "center-myself-recharge"
  }, {
    path: "/center/myself/thirdPartyBinding",
    component: _1aa3a8ae,
    name: "center-myself-thirdPartyBinding"
  }, {
    path: "/center/sell/sellBargainGoods",
    component: _a1fd3810,
    name: "center-sell-sellBargainGoods"
  }, {
    path: "/center/sell/sellOrder",
    component: _d04fc12c,
    name: "center-sell-sellOrder"
  }, {
    path: "/center/sell/sellOutGoods",
    component: _0296bc3c,
    name: "center-sell-sellOutGoods"
  }, {
    path: "/center/sell/Sellreceive",
    component: _78499102,
    name: "center-sell-Sellreceive"
  }, {
    path: "/center/sell/sellSuccess",
    component: _d70d36c2,
    name: "center-sell-sellSuccess"
  }, {
    path: "/center/sell/sellTab",
    component: _907ba59e,
    name: "center-sell-sellTab"
  }, {
    path: "/center/myself/accountSecurity/changePassword",
    component: _d32a4740,
    name: "center-myself-accountSecurity-changePassword"
  }, {
    path: "/center/myself/accountSecurity/changePhone",
    component: _53b55b79,
    name: "center-myself-accountSecurity-changePhone"
  }, {
    path: "/",
    component: _7af05127,
    name: "index"
  }, {
    path: "/black/:sn",
    component: _4d249b56,
    name: "black-sn"
  }, {
    path: "/detail/:sn?",
    component: _4f863cde,
    name: "detail-sn"
  }, {
    path: "/JDetail/:sn?",
    component: _d4e8464c,
    name: "JDetail-sn"
  }, {
    path: "/news/:code",
    component: _1c2ca82e,
    name: "news-code"
  }, {
    path: "/goods/:sn?/order",
    component: _385da63f,
    name: "goods-sn-order"
  }, {
    path: "/goods/:sn?/pay",
    component: _543ed64e,
    name: "goods-sn-pay"
  }, {
    path: "/goods/:sn?/success",
    component: _29b88a34,
    name: "goods-sn-success"
  }, {
    path: "/wares/:sn/order",
    component: _f6b76702,
    name: "wares-sn-order"
  }, {
    path: "/wares/:sn/pay",
    component: _77c869ce,
    name: "wares-sn-pay"
  }, {
    path: "/wares/:sn/success",
    component: _7eeea074,
    name: "wares-sn-success"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
